exports.onInitialClientRender = () => {
  const site__wrapper = document.getElementById('___gatsby');
  site__wrapper && site__wrapper.classList.add('is-loaded');
  // const heroWrapper = document.getElementById("hero__wrapper")
  // heroWrapper && heroWrapper.classList.add("is-visible")
  const fadeAnimation = document.getElementById('fade-ani');
  fadeAnimation && fadeAnimation.classList.add('is-visible');
};
exports.onRouteUpdate = () => {
  // const heroWrapper = document.getElementById("hero__wrapper")
  // heroWrapper && heroWrapper.classList.add("is-visible")
  const fadeAnimation = document.getElementById('fade-ani');
  fadeAnimation && fadeAnimation.classList.add('is-visible');
};
exports.onPreRouteUpdate = () => {
  // const heroWrapper = document.getElementById("hero__wrapper")
  // heroWrapper && heroWrapper.classList.remove("is-visible")
  const fadeAnimation = document.getElementById('fade-ani');
  fadeAnimation && fadeAnimation.classList.remove('is-visible');
};
